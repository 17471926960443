<div class="flex items-center border-2 border-normal rounded-full px-2 py-1 text-sm">
  <div class="flex-shrink-0">
    <img src="assets/img/zoho-subscriptions-logo.png" alt="Zoho Subscription Logo" width="24">
  </div>
  <a href="https://subscriptions.zoho.com/" target="_blank" class="font-medium ml-1">Zoho Subscription</a>
</div>





