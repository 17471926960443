export class CommonField  {
    public _failure: boolean = false;
    public _message!: string;
    public _tenantName!: string;
    public _token!: string;
    public language!: string;
    public _userName!: string;
    public schoolId!: number | string | null | undefined;
    public tenantId!: string;
    public updatedBy!: string;
    constructor(){
    }
}