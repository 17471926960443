import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CustomLayoutComponent } from './custom-layout/custom-layout.component';
import { VexRoutes } from '../@vex/interfaces/vex-route.interface';
import { QuicklinkModule, QuicklinkStrategy } from 'ngx-quicklink';
import { StatusCode404Component } from './errors/status-code404/status-code404.component';

const routes: VexRoutes = [
  {
    path: 'manager',
    loadChildren: () => import('./pages/manager/manager.module').then(m => m.ManagerModule),
  },
  {
    path: 'page-not-found',
    component: StatusCode404Component
  },
  { 
    path: '',
    redirectTo: '/manager/login',
    pathMatch: 'full'
  },
  { 
    path: '**',
    redirectTo: '/page-not-found'
  }
 

  // {
  //   path: '',
  //   component: CustomLayoutComponent,
  //   children: [

  //   ]
  // }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    preloadingStrategy: QuicklinkStrategy,
    scrollPositionRestoration: 'enabled',
    relativeLinkResolution: 'corrected',
    anchorScrolling: 'enabled'
  })],
  exports: [RouterModule, QuicklinkModule]
})
export class AppRoutingModule {
}
