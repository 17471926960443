import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { DefaultValuesService } from '../common/default-values.service';
import { BehaviorSubject, fromEvent, map, merge, Observable, Observer } from 'rxjs';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ProfilesTypes } from '../enums/profiles.enum';
@Injectable({
  providedIn: 'root'
})
export class CommonService {
  apiUrl: string = environment.apiURL;

  private changeLanguage = new BehaviorSubject(false);
  changedLanguage = this.changeLanguage.asObservable();

  private userLoginError = new BehaviorSubject(false);
  userLoginErrorTriggered = this.userLoginError.asObservable();

  private triggerUserActivity = new BehaviorSubject(false);
  triggeredUserActivity = this.triggerUserActivity.asObservable();
  httpOptions: { headers: any; };
  httpOptionsForBackup: { headers: HttpHeaders; };
  profiles = ProfilesTypes;

  constructor(
    private http: HttpClient,
    private defaultValuesService: DefaultValuesService,
    private router: Router,
    public translate: TranslateService,
  ) { this.httpOptions = {
    headers: new HttpHeaders({
      'Cache-Control': 'no-cache',
      'Pragma': 'no-cache',
    })
  }
  this.httpOptionsForBackup = {
    headers: new HttpHeaders({
      //"Content-type": "application/json",
       observe: 'events',
       responseType: 'blob'
    }),

    
  }
}

  // public download(obj: DatabaseBackupModel) {
  //   const params = new HttpParams()
  //   .set('sort', "description")
  //   .set('page',"2");
  //   const headers = new HttpHeaders()
  //   .set('Content-Type', 'application/json')
  //   obj = this.defaultValuesService.getAllMandatoryVariable(obj);
  //   let apiurl = this.apiUrl + obj._tenantName + "/DBBackup/download1";
  //   return this.http.get(apiurl, { 'params': params, 'headers': headers })
  //   /*return this.http.get(`${this.url}/download?fileName=${fileName}`, {
  //     reportProgress: true,
  //     observe: 'events',
  //     responseType: 'blob',
  //   });*/
  // }

  getIpAddress() {
    return this.http.get('https://api64.ipify.org/?format=json');
  }

  triggerLanguageChange(data: boolean) {
    this.changeLanguage.next(data);
  }

  setUserActivity(data: boolean) {
    this.triggerUserActivity.next(data);
  }

  setUserLoginError(data: boolean) {
    this.userLoginError.next(data);
  }


  checkAndRouteTo404() {
    if (this.defaultValuesService.getToken()) {
      this.router.navigate(['/manager', 'error', '404']);
    } else {
      this.router.navigate(['/error', '404']);
    }
  }



  logoutUser() {
    this.clearStorage();
    this.router.navigateByUrl('/');
  }

  checkTokenValidOrNot(message: string) {
    if((message).toLowerCase() === 'token not valid') {
      // this.snackbar.open('Another login with the same credentials was detected. Therefore, you have been logged out of the system.', '', {
      //   duration: 10000
      // });

      this.setUserLoginError(true);
      this.clearStorage();
      this.router.navigateByUrl('/');
    }
  }

  isOnline() {
    return merge<any>(
      fromEvent(window, 'offline').pipe(map(() => false)),
      fromEvent(window, 'online').pipe(map(() => true)),
      new Observable((sub: Observer<boolean>) => {
        sub.next(navigator.onLine);
        sub.complete();
      }));
      // return new Observable((sub: Observer<boolean>) => {
      //   sub.next(navigator.onLine);
      //   sub.complete();
      // });
  }

  clearStorage(){
      const getPhotoAndFooter = this.defaultValuesService.getPhotoAndFooter();
      sessionStorage.clear();
      this.setUserActivity(true);
      // this.defaultValuesService.setPhotoAndFooter(getPhotoAndFooter);
      this.translate.addLangs(['en', 'fr']);
      this.translate.setDefaultLang('en');
    }
}

