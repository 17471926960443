import { Component, HostBinding, Input, OnDestroy, OnInit } from '@angular/core';
import { LayoutService } from '../../services/layout.service';
import { ConfigService } from '../../services/config.service';
import { map } from 'rxjs/operators';
import icMenu from '@iconify/icons-ic/twotone-menu';
import icSearch from '@iconify/icons-ic/twotone-search';
import { fadeInUp400ms } from '../../../@vex/animations/fade-in-up.animation';
import { stagger40ms } from '../../../@vex/animations/stagger.animation';
import { TranslateService } from '@ngx-translate/core';
import { CommonService } from 'src/app/services/common.service';
import { DefaultValuesService } from 'src/app/common/default-values.service';

@Component({
  selector: 'vex-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss'],
  animations: [
    fadeInUp400ms,
    stagger40ms
  ],
})
export class ToolbarComponent implements OnInit,OnDestroy {

  @Input()
  mobileQuery!: boolean;

  @Input()
  @HostBinding('class.shadow-b')
  hasShadow: boolean = false;
  isVerticalLayout$ = this.configService.config$.pipe(map((config: any) => config.layout === 'vertical'));
  isNavbarBelowToolbar$ = this.configService.config$.pipe(map((config: any) => config.navbar.position === 'below-toolbar'));

  icSearch = icSearch;
  icMenu = icMenu;
  userPhoto: string;
  
  constructor(private layoutService: LayoutService,
    private configService: ConfigService,
    private commonService: CommonService,
    public translateService: TranslateService,
    public defaultValuesService:DefaultValuesService,
    ) {
      this.getUserPhoto();
  }

  ngOnInit() {
  }

  logOut() {
    this.commonService.logoutUser();
  }

  getUserPhoto() {
    let photo = this.defaultValuesService.getuserPhoto();

    if (photo) {
      this.userPhoto = 'data:image/png;base64,' + photo;
    } else {
      this.userPhoto = '../../../assets/img/profilePic.jpg';
    }
  }

  openQuickpanel() {
    this.layoutService.openQuickpanel();
  }

  openSidenav() {
    this.layoutService.openSidenav();
  }


  ngOnDestroy(){
  }
}
