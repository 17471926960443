<div (mouseenter)="onMouseEnter()" (mouseleave)="onMouseLeave()" [class.collapsed]="collapsed"
  [class.open]="collapsed && collapsedOpen$ | async" class="sidenav flex flex-col">
  <div class="sidenav-toolbar flex-none flex items-center justify-between">
    <img *ngIf="collapsed" src="assets/img/opensislogo.png" class="select-none logo-small flex-none" alt="openSIS Logo">
    <img *ngIf="collapsed" src="assets/img/opensis.png" class="select-none logo-full flex-none" style="width: 131.39px !important;" alt="openSIS">
    <img *ngIf="!collapsed" src="assets/img/opensis.png" class="select-none logo-full flex-none" alt="openSIS">
   
   
    <button (click)="toggleCollapse()" *ngIf="showCollapsePin$ | async"
      class="expand-btn w-9 h-9 -mr-2 leading-none flex-none hidden lg:block text-black" mat-icon-button type="button">
      <mat-icon *ngIf="!collapsed" [icIcon]="icCollapseSidebar" size="20px"></mat-icon>
      <mat-icon *ngIf="collapsed" [icIcon]="icExpandSidebar" size="20px"></mat-icon>
    </button>
  </div>
  
  <vex-scrollbar class="flex-auto">
    <div class="sidenav-items">
      <vex-sidenav-item *ngFor="let item of items; trackBy: trackByRoute"
                        [item]="item"
                        [level]="0"></vex-sidenav-item>
    </div>
  </vex-scrollbar>
</div>
<button (click)="toggleCollapse()" *ngIf="showCollapsePin$ | async"
  class="collapse-btn w-8 h-8 -mr-2 leading-none flex-none hidden lg:block text-black" mat-icon-button type="button">
  <mat-icon *ngIf="!collapsed" [icIcon]="icCollapseSidebar" size="20px"></mat-icon>
  <mat-icon *ngIf="collapsed" [icIcon]="icExpandSidebar" size="20px"></mat-icon>
</button>