import { Component, Input, OnInit } from '@angular/core';
import { trackByRoute } from '../../utils/track-by';
import { NavigationService } from '../../services/navigation.service';
import icCollapseSidebar from '@iconify/icons-ic/twotone-switch-left';
import icExpandSidebar from '@iconify/icons-ic/twotone-switch-right';
import icArrowDropDown from '@iconify/icons-ic/arrow-drop-down';
import { LayoutService } from '../../services/layout.service';
import { ConfigService } from '../../services/config.service';
import { map, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { CommonService } from 'src/app/services/common.service';
import { DefaultValuesService } from 'src/app/common/default-values.service';

@Component({
  selector: 'vex-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss']
})
export class SidenavComponent implements OnInit {

  @Input() collapsed: boolean | undefined;
  globalCollapseValue: any;
  collapsedOpen$ = this.layoutService.sidenavCollapsedOpen$;
  title$ = this.configService.config$.pipe(map((config: any) => config.sidenav.title));
  imageUrl$ = this.configService.config$.pipe(map((config: any) => config.sidenav.imageUrl));
  showCollapsePin$ = this.configService.config$.pipe(map((config: any) => config.sidenav.showCollapsePin));

  items = this.navigationService.items;
  trackByRoute: any = trackByRoute;
  icCollapseSidebar = icCollapseSidebar;
  icExpandSidebar = icExpandSidebar;
  icArrowDropDown = icArrowDropDown;
  userName: any;
  destroySubject$ = new Subject<void>();

  constructor(
    private navigationService: NavigationService,
    private layoutService: LayoutService,
    private configService: ConfigService,
    public translateService: TranslateService,
    private commonService: CommonService,
    private defaultValuesService: DefaultValuesService
  ) {

    this.navigationService.menuItems.pipe(takeUntil(this.destroySubject$)).subscribe((res) => {
      if (res) {
        this.items = this.navigationService.items;
      }
    });
  }

  ngOnInit() {
  }

  expandSidebar(){
    this.layoutService.expandSidenav();
  }


  onMouseEnter() {
    this.layoutService.collapseOpenSidenav();
  }

  onMouseLeave() {
    this.layoutService.collapseCloseSidenav();
  }

  toggleCollapse() {
    if (this.collapsed) {
      this.defaultValuesService.setCollapseValue(false);
      this.layoutService.expandSidenav()
    } else {
      this.layoutService.collapseSidenav();
      this.defaultValuesService.setCollapseValue(true);
    }

  }
  logOut() {
    this.commonService.logoutUser();
  }


  ngOnDestroy() {
    this.navigationService.changeMenuItemsStatus(false);
    this.destroySubject$.next();
    this.destroySubject$.complete();
  }
}
