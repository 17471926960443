<div class="toolbar w-full px-gutter flex justify-between items-center" vexContainer>
  <button (click)="openSidenav()" [fxHide]="!mobileQuery" mat-icon-button type="button">
    <mat-icon [icIcon]="icMenu"></mat-icon>
  </button>

  <!-- <a [routerLink]="['/school/dashboards']"
     class="ltr:ml-2 rtl:ml-2 small-mobile-logo">
    <img src="assets/img/opensis.png" class="w-8 select-none" alt="openSIS Logo">
  </a> -->

  <div class="items-right">
      <vex-select-bar @stagger></vex-select-bar>
  </div>

  <div #megaMenuOriginRef class="rtl:mr-2">
    <div class="-mx-1 flex items-center">

    <div [matMenuTriggerFor]="addNewMenu" class="cursor-pointer">
      <img class="h-9 w-9 rounded-full ml-3 shadow-md border-2 border-white" [src]="userPhoto" alt="Profile picture">
    </div>
    <mat-menu #addNewMenu="matMenu" [overlapTrigger]="false" xPosition="before" yPosition="below">
      <div class="px-4 pt-1 pb-3">
        <h4 class="text-blue font-semibold">{{this.defaultValuesService.getFullUserName()}}</h4>
        <p class="text-gray-500">{{'manager' | translate}}</p>
      </div>
      <hr>
      <button mat-menu-item (click)="logOut()">
        <mat-icon>logout</mat-icon>
        <span>{{'logout' | translate}}</span>
      </button>
    </mat-menu>
    </div>
  </div>
</div>

<vex-navigation *ngIf="(isVerticalLayout$ | async) && isNavbarBelowToolbar$ | async"
                [fxHide]="mobileQuery"></vex-navigation>
