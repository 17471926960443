import { Injectable } from '@angular/core';
import { CommonField } from '../models/common-field.model';
import { TranslateService } from '@ngx-translate/core';
import { CryptoService } from '../services/Crypto.service';
import { BehaviorSubject, Subject } from 'rxjs';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root',
})
export class DefaultValuesService {
  commonModel: CommonField = new CommonField();
  private photoChange = new Subject<string>();

  constructor(
    public translateService: TranslateService,
  ) {
  }
  
  getEmailId() {
    let email = JSON.parse(sessionStorage.getItem('email')!);
    return email;
  }

  setToken(token: string) {
    return new Promise((resolve, reject) => {
      sessionStorage.setItem('token', JSON.stringify(token));
      resolve('');
    })
  }

  getToken() {
    this.commonModel._token = JSON.parse(sessionStorage.getItem('token')!);
    return JSON.parse(sessionStorage.getItem('token')!);
  }


  getuserMembershipID() {
    return JSON.parse(sessionStorage.getItem('userMembershipID')!);
  }

  getManagerId() {
    return JSON.parse(sessionStorage.getItem('managerId')!);
  }

  getuserMembershipName() {
    return JSON.parse(sessionStorage.getItem('membershipName')!);
  }


  getUserMembershipType() {
    return JSON.parse(sessionStorage.getItem('membershipType')!);
  }

  setUserMembershipType(membershipType?: string) {

    if (membershipType) {
      sessionStorage.setItem("membershipType", JSON.stringify(membershipType));
    }
  }

  translateKey(key: string | string[]) {
    let trnaslateKey;
    this.translateService.get(key).subscribe((res: string) => {
      trnaslateKey = res;
    });
    return trnaslateKey;
  }

  getuserPhoto() {
    return JSON.parse(sessionStorage.getItem('userPhoto')!);
  }

  //new changes for set---------------------------------
  setFullUserName(fullUserName: string) {
    sessionStorage.setItem("fullUserName", JSON.stringify(fullUserName));
  }
  setEmailId(email: string) {
    sessionStorage.setItem("email", JSON.stringify(email));
  }
  setUserName(user: string) {
    sessionStorage.setItem("user", JSON.stringify(user));
  }
  setUserId(userId: string) {
    sessionStorage.setItem("userId", JSON.stringify(userId));
  }
  setUserPhoto(userPhoto: string) {
    sessionStorage.setItem("userPhoto", JSON.stringify(userPhoto));
  }
  setUserMembershipID(userMembershipID: string) {
    sessionStorage.setItem("userMembershipID", JSON.stringify(userMembershipID));
  }
  setManagerId(managerId: number) {
    sessionStorage.setItem("managerId", JSON.stringify(managerId));
  }
  setuserMembershipName(membershipName: string) {
    sessionStorage.setItem("membershipName", JSON.stringify(membershipName));
  }


  getCollapseValue() {
    return JSON.parse(localStorage.getItem("collapseValue")!);
  }

  getFullUserName() {
    return JSON.parse(sessionStorage.getItem("fullUserName"));
  }

  setErrorMessage(errorMessage: string) {
    sessionStorage.setItem("httpError", JSON.stringify(errorMessage));
  }

  getHttpError() {
    return JSON.parse(sessionStorage.getItem("httpError")!);
  }

  //new changes foe localStorage set


  setCollapseValue(collapseValue: boolean) {
    localStorage.setItem("collapseValue", JSON.stringify(collapseValue));
  }

  setPhotoAndFooter(data: any) {
     sessionStorage.setItem('photoAndFooter', JSON.stringify(data));
  }
  getPhotoAndFooter() {
    return JSON.parse(sessionStorage.getItem('photoAndFooter')!);
  }

  //using of RxJs
  sendPhoto(data: string) {
    this.photoChange.next(data);
  }

  setPageSize(data: number) {
    sessionStorage.setItem('pageSize', JSON.stringify(data));
  }

  getPageSize(): number {
    return JSON.parse(sessionStorage.getItem('pageSize'));
  }

  getSubscriptionStatus(planExpiryDate, zohoPlanStatus) {
    const browserTime = moment.utc(new Date());
    const zohoExpiryTime = moment.utc(planExpiryDate);
    let status;

    if (zohoPlanStatus === 'trial_expired') {
      return status = 'TRIAL EXPIRED';
    } else if (zohoPlanStatus === 'live') {
      return status = 'LIVE';
    } else if (zohoPlanStatus === 'trial') {
      return status = 'TRIAL';
    } else if (zohoPlanStatus === 'expired') {
      return status = 'EXPIRED';
    } else if (zohoPlanStatus === 'trial_expired') {
      return status = 'TRIAL EXPIRED';
    } else if (zohoPlanStatus === 'cancelled') {
      return status = 'CANCELLED';
    } else if (zohoPlanStatus === 'non_renewing') {
      return status = 'CANCELLED RENEWAL';
    } else if (zohoPlanStatus === 'unpaid') {
      return status = 'UNPAID';
    }
  }

  checkExpiryDate(planExpiryDate) {
    const browserTime = moment.utc(new Date());
    const zohoExpiryTime = moment.utc(planExpiryDate);
    return Math.ceil((zohoExpiryTime.diff(browserTime, 'hours', true) + 4) / 24);
  }
}
